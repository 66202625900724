/* eslint-disable camelcase */
import parse, { domToReact } from 'html-react-parser';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Slider from '../../renderComponents/Slider/Slider';
import './CategoryPageContent.scss';
import productionConfiguration from '../../../config/default.json';
import readConfig from '../../utils/readClientConfiguration';

const pointingToSelf = link => {
  const base = readConfig(productionConfiguration, 'base');

  // Check if the url contains the specific domain we're trying to point to
  return (link.hostname === `www.${base}`) || (link.hostname === base);
}

function replaceHTMLNodesWithReact(domNode) {
  // null return means don't replace anything - keep the original
  const { name, attribs, children } = domNode;

  try {
    // Rewrite the links so that they work correctly on the PWA side
    if (name === 'a' && attribs.href) {
      const rawLink = attribs.href;
      // Try to parse the link
      try {
        const link = new URL(attribs.href);
        // Rewrite links that are pointing to us
        if(pointingToSelf(link)) {
          const processedLink = link.pathname.endsWith('/') ? link.pathname.slice(0, -1) : link.pathname;
          return <Link to={processedLink}>{domToReact(children)}</Link>;
        }
      } catch (e) {
        // Rewrite the links that are pointing in a relative way (implicitly us)
        const processedLink = rawLink.endsWith('/') ? rawLink.slice(0, -1) : rawLink;
        return <Link to={processedLink}>{domToReact(children)}</Link>;
      }
    }

    if (attribs && attribs['data-content-type'] === 'slider') {
      const sliderProps = {
        autoplay: attribs['data-autoplay'] === 'true',
        autoplaySpeed: +attribs['data-autoplay-speed'],
        appearance: attribs['data-appearance'],
        fade: attribs['data-fade'] === 'true',
        showArrows: attribs['data-show-arrows'] === 'true',
        showDots: attribs['data-show-dots'] === 'true',
        infinite: attribs['data-infinite-loop'] === 'true',
        pbStyle: attribs['data-pb-style'],
      };

      return (
        <Slider {...sliderProps} cssClasses={[attribs.class]} isCategory>
          {domToReact(children, { replace: replaceHTMLNodesWithReact })}
        </Slider>
      );
    }

    if (name === 'div') {
      if (attribs['data-background-images']) {
        const { 'data-background-images': images, ...rest } = attribs;
        const { desktop_image } = JSON.parse(images.replace(/\\/g, ''));

        if ('class' in rest) {
          rest.className = rest.class;
          delete rest.class;
        }

        return (
          <div {...rest} style={{ backgroundImage: `url(${desktop_image})` }}>
            {domToReact(children, { replace: replaceHTMLNodesWithReact })}
          </div>
        );
      }
    }
  } catch (error) {
    console.log('ERROR: ', error);
    return null;
  }

  return null;
}

const CategoryPageContent = ({ data }) => {
  if (!data) {
    return null;
  }

  const pageContent = data.replace(/#html-body/g, '.category');

  return (
    <div className="pagebuilder">
      {parse(pageContent, {
        replace: replaceHTMLNodesWithReact,
      })}
    </div>
  );
};

export default memo(CategoryPageContent);
