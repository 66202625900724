import loadable from '@loadable/component';
import React, {useEffect, useRef, useState} from 'react';
import './Category.scss';
import {useListingContext} from "../../context/ListingContext";
import SeoBlock from "../../components/SeoBlock";
import {useQueryListing} from "../../hooks/useQueryListing";
import QuarticonProductSlider from "../../renderComponents/ProductsSlider/QuarticonProductSlider";
import CategoryPageContent from "../../components/CategoryPageContent/CategoryPageContent";
import {productsInCatalogEvent} from "../../hooks/useAnalytics";
import ProductFilters from "../../components/ProductFilters/ProductFilters";
import ListingNav from "../../components/ListingNav/ListingNav";
import HorizontalFilter from "../../components/ProductFilters/HorizontalFilter";
import Paginator from "../../components/ListingProducts/Paginator";
import ListingProducts from "../../components/ListingProducts/ListingProducts";

const CategoriesList = loadable(() => import('../../components/Category/CategoriesList/CategoriesList'));
const Breadcrumbs = loadable(() => import('../../components/Category/Breadcrumbs/Breadcrumbs'));

const CategoryPage = ({uid, pageData, schema}) => {
    const {buildListingParamsFromQuery} = useQueryListing()

    const [scrollToTop, setScrollToTop]=useState(false)

    const myRef=useRef(null)
    const {
        products,
        listingParams,
        resetListing,
        initListing,
        setInitialListingParams,
        setListingParams,
        loading
    } = useListingContext();

    const {
        totalPages = 0,
    } = useListingContext().listingData

    const params = [
        {
            key: 'search',
            value: "",
        },
        {
            key: 'filters',
            value: [],
        },
        {
            key: 'category_uid',
            value: uid,
        }
    ];
    useEffect(() => {
        if (uid) {
            resetListing(params);
            setInitialListingParams(params);
            const _params = buildListingParamsFromQuery(params);
            setListingParams(initListing(_params))
            setScrollToTop(false)
        }
    }, [uid])

    const filterHandle = (newFilters) => {
        listingParams.currentPage = 1;
        listingParams.filters = newFilters;
        setListingParams({...listingParams});
        setScrollToTop(true)
    }

    useEffect(() => {
        if (products.length) {
            productsInCatalogEvent(products, pageData)
        }

    }, [products]);

    const {
        name = null,
        page_layout = 'category-full-width',
        subcategory_items_list = 0,
        hideQuarticon = false,
        category_description = null,
        category_description_bottom = null,
        is_virtual_category = false,
        children=[]
    } = pageData

    let classes = ['category']
    if (page_layout) {
        classes.push(`layout-${page_layout}`)
    }

    let showSorting = true
    let hideCategoriesList = !children.length > 0
    let showVerticalFilters = true
    let showHorizontalFilters = !showVerticalFilters
    const horizontalFiltersAttributes = []
    if (page_layout == 'inspiration') {
        hideCategoriesList = true
        // showSorting = false
        showVerticalFilters = false
        showHorizontalFilters = true
        horizontalFiltersAttributes.push('inspiration_category')
        horizontalFiltersAttributes.push('stil')
    }

    useEffect(() => {
        if (loading == false && scrollToTop) {
            myRef.current.scrollIntoView({behavior: 'smooth'});
        }else if (loading == false && scrollToTop==false) {
            setScrollToTop(true)
        }
    }, [loading])

    return (
        <>
            <SeoBlock routeData={pageData}/>
            <div className={classes.join(' ')}>
                {<Breadcrumbs isCategory routeData={pageData}/>}
                <h1 className="category__title">
                    {name}
                </h1>
                {!hideCategoriesList &&
                    <CategoriesList currentCategory={pageData}/>}

                {category_description &&
                    <CategoryPageContent data={category_description}/>}

                {!hideQuarticon && is_virtual_category === 0 &&
                    <QuarticonProductSlider type="category_page" categoryId={uid} cnt={4}/>}

                <div className="columns" ref={myRef} >
                    {showVerticalFilters && <div className="columns__left">
                        <ProductFilters
                            filterHandle={filterHandle}
                        />
                    </div>}

                    {totalPages > 0 &&
                        <div className="columns__right">

                            <ListingNav showSorting={showSorting}>
                                {showHorizontalFilters &&
                                    <HorizontalFilter
                                        filters={horizontalFiltersAttributes}
                                        filterHandle={filterHandle}/>}
                            </ListingNav>

                            <ListingProducts filterHandle={filterHandle}/>
                            {totalPages > 1 &&
                                <Paginator handle={filterHandle}/>}
                        </div>
                    }

                </div>
                {pageData && category_description_bottom &&
                    <CategoryPageContent data={category_description_bottom}/>}
            </div>
        </>
    );
}
export default CategoryPage;
